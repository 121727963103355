import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./Hoc/Layout";
import "./styles/common.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
} from "@apollo/client";
import { directionalLink, errorLink } from "./apollo";
import { useDispatch, useSelector } from "react-redux";
import { setApolloClient } from "./utils/redux/cache";
import { getToken } from "./utils/getToken";
import { loginUser, logoutUser } from "./utils/redux/auth";
import { ToastContainer, toast } from "react-toastify";
import { Suspense } from "react";
import NProgress from "nprogress";
import { SearchVisibilityProvider } from "./context/SearchBoxContext";
import { MetaDataProvider } from "./context/MetaDataContext";
import PageNotFound from "./containers/PageNotFound";
import SchoolOfBusinessNew from "./containers/acedemic-programmmes-school-of-business/SchoolOfBusinessNew";

const AboutUsPage = React.lazy(() => import("./containers/AboutUsPage"));
const AboutUsHeritage = React.lazy(() =>
  import("./containers/aboutus-heritage/AboutUsHeritage")
);
const MissionVission = React.lazy(() =>
  import("./containers/aboutus-mission-vission/MissionVission")
);
const AboutUsDirector = React.lazy(() =>
  import("./containers/aboutus-director/AboutUsDirector")
);
const AboutusDirectorMessage = React.lazy(() =>
  import("./containers/aboutus-director-message/AboutusDirectorMessage")
);
const AboutusIndustryAdvisoryCouncils = React.lazy(() =>
  import(
    "./containers/aboutus-Industry-advisory-councils/AboutusIndustryAdvisoryCouncils"
  )
);
const AboutUsXlriRanking = React.lazy(() =>
  import("./containers/aboutus-xlri-ranking/AboutUsXlriRanking")
);
const ProgramsRecognition = React.lazy(() =>
  import("./containers/programs-recognition/ProgramsRecognition")
);
const OurBrandIdentity = React.lazy(() =>
  import("./containers/our-brand-identity/OurBrandIdentity")
);
const AboutUsJesuitFoundingFathers = React.lazy(() =>
  import(
    "./containers/aboutus-jesuit-founding-fathers/AboutUsJesuitFoundingFathers"
  )
);
const ExecutiveEducation = React.lazy(() =>
  import("./containers/executive-education/ExecutiveEducation")
);
const ResearchPublications = React.lazy(() =>
  import(
    "./containers/faculty-research-research-publications/ResearchPublications"
  )
);
const VisitingFacultyMembers = React.lazy(() =>
  import(
    "./containers/faculty-research-visiting-faculty-members/VisitingFacultyMembers"
  )
);
const ConsultancyServices = React.lazy(() =>
  import(
    "./containers/executive-education-consultancy-services/ConsultancyServices"
  )
);
const CompanyProgrammes = React.lazy(() =>
  import(
    "./containers/executive-education-company-programmes/CompanyProgrammes"
  )
);
const Faq = React.lazy(() => import("./containers/faq/Faq"));
const FellowProgrammes = React.lazy(() =>
  import("./containers/academic-programmes-fellow-programmes/FellowProgrammes")
);
const PlacementReports = React.lazy(() =>
  import(
    "./containers/corporate-relations-placement-placement-reports/PlacementReports"
  )
);
const ResearchFacultyInnovation = React.lazy(() =>
  import(
    "./containers/faculty-research-research-faculty-Innovation/ResearchFacultyInnovation"
  )
);
const SeminarsPanelDiscussion = React.lazy(() =>
  import(
    "./containers/corporate-relations-placement-conclaves-seminars-panel-discussion/SeminarsPanelDiscussion"
  )
);
const Faqs = React.lazy(() =>
  import("./containers/corporate-relations-placement-faq/Faqs")
);
const GivingToXLRI = React.lazy(() =>
  import("./containers/giving-to-xlri/GivingToXLRI")
);
const PlacementCommittee = React.lazy(() =>
  import("./containers/placement-committee/PlacementCommittee")
);
const Resources = React.lazy(() => import("./containers/resources/Resources"));
const BehavioralLab = React.lazy(() => import("./containers/resources/BehaviouralLab/BehaviouralLab"))
const Gallery = React.lazy(() => import("./containers/gallery/Gallery"));
const GalleryLibrary = React.lazy(() =>
  import("./containers/gallery-library/GalleryLibrary")
);
const FireChatInterview = React.lazy(() =>
  import(
    "./containers/corporate-relations-placement-fire-chat-interview/FireChatInterview"
  )
);
const RecruitmentActivities = React.lazy(() =>
  import("./containers/recruitment-activities/RecruitmentActivities")
);
const CareerTransformation = React.lazy(() =>
  import("./containers/career-transformation/CareerTransformation")
);
const ProfessionalMentorship = React.lazy(() =>
  import("./containers/professional-mentorship/ProfessionalMentorship")
);
const TataFoundation = React.lazy(() =>
  import("./containers/tata-foundation/TataFoundation")
);
const GenderEquality = React.lazy(() =>
  import("./containers/gender-equality/GenderEquality")
);
const TestmonialMicro = React.lazy(() =>
  import(
    "./containers/MicroWebsite/microwebsite-5s/testmonialmicro/TestmonialMicro"
  )
);
const Home = React.lazy(() =>
  import("./containers/MicroWebsite/microwebsite-5s/home/Home")
);
const About = React.lazy(() =>
  import("./containers/MicroWebsite/microwebsite-5s/about/About")
);
const Programmes = React.lazy(() =>
  import("./containers/MicroWebsite/isep-microwebsite/programmes/Programmes")
);
const InternationalReach = React.lazy(() =>
  import(
    "./containers/MicroWebsite/isep-microwebsite/international-reach/InternationalReach"
  )
);
const LifeXlri = React.lazy(() =>
  import("./containers/MicroWebsite/life-of-xlri/LifeXlri")
);
const Commitee = React.lazy(() =>
  import("./containers/MicroWebsite/committee-1/Commitee")
);
const Commitee3 = React.lazy(() =>
  import("./containers/MicroWebsite/committee-3/Commitee3")
);
const Commitee2 = React.lazy(() =>
  import("./containers/MicroWebsite/committee-2/Commitee2")
);
const Commitee4 = React.lazy(() =>
  import("./containers/MicroWebsite/committee-4/Commitee4")
);
const Commitee5 = React.lazy(() =>
  import("./containers/MicroWebsite/committee/Commitee5")
);
const ZonalChampions = React.lazy(() =>
  import("./containers/MicroWebsite/zonal-champions/ZonalChampions")
);
const BestPractices = React.lazy(() =>
  import("./containers/MicroWebsite/best-practices/BestPractices")
);
const BestPracticesSort = React.lazy(() =>
  import("./containers/MicroWebsite/best-practices-sort/BestPracticesSort")
);
const BestPracticesShine = React.lazy(() =>
  import("./containers/MicroWebsite/best-practices-shine/BestPracticesShine")
);
const BestPracticesSustain = React.lazy(() =>
  import(
    "./containers/MicroWebsite/best-practices-sustain/BestPracticesSustain"
  )
);
const BestPracticeSetInOrder = React.lazy(() =>
  import(
    "./containers/MicroWebsite/best-practices-set-in-order/BestPracticeSetInOrder"
  )
);
const BestPracticesStandardize = React.lazy(() =>
  import(
    "./containers/MicroWebsite/best-practices-standardize/BestPracticesStandardize"
  )
);
const GetInvolved = React.lazy(() =>
  import("./containers/MicroWebsite/get-involved/GetInvolved")
);
const SatInfo = React.lazy(() =>
  import("./containers/MicroWebsite/satinfo/SatInfo")
);
const AboutusCareer = React.lazy(() =>
  import("./containers/aboutus-career/AboutusCareers")
);
const AboutusCareerDetail = React.lazy(() =>
  import("./containers/aboutus-careerdetail/AboutusCareerDetails")
);
const LifeOnCampus = React.lazy(() =>
  import("./containers/life-on-campus/LifeOnCampus")
);
const EdhrmXlri = React.lazy(() =>
  import("./containers/MicroWebsite/edhrm-xlri/EdhrmXlri")
);
const NewsroomDetailPage = React.lazy(() =>
  import("./containers/MicroWebsite/newsroom-detail-page/NewsroomDetailPage")
);
const AboutUs = React.lazy(() =>
  import("./containers/MicroWebsite/post-graduate-diploma-about-us/AboutUs")
);
const PostGraduateDiploma = React.lazy(() =>
  import("./containers/MicroWebsite/post-graduate-diploma/PostGraduateDiploma")
);
const IsepContactUs = React.lazy(() =>
  import("./containers/MicroWebsite/isep-contact-us/IsepContactUs")
);
const AboutLibraryCatalogue = React.lazy(() =>
  import(
    "./containers/MicroWebsite/about-library-catalogue/AboutLibraryCatalogue"
  )
);
const Dissertations = React.lazy(() =>
  import("./containers/MicroWebsite/about-library-catalogue/Dissertations")
);
const Newspapers = React.lazy(() =>
  import("./containers/MicroWebsite/about-library-catalogue/Newspapers")
);
const Magazines = React.lazy(() =>
  import("./containers/MicroWebsite/about-library-catalogue/Magazines")
);
const AboutLibraryDatabases = React.lazy(() =>
  import(
    "./containers/MicroWebsite/about-library-databases/AboutLibraryDatabases"
  )
);
const LibraryContactus = React.lazy(() =>
  import("./containers/MicroWebsite/library-contact-us/LibraryContactus")
);
const AlumniEndowmentFund = React.lazy(() =>
  import("./containers/MicroWebsite/alumni-endowment-fund/AlumniEndowmentFund")
);
const AboutusLibraryCollection = React.lazy(() =>
  import(
    "./containers/MicroWebsite/about-us-library-collection/AboutusLibraryCollection"
  )
);
const Newsroom = React.lazy(() =>
  import("./containers/MicroWebsite/newsroom/Newsroom")
);
const TravelAndAccommodation = React.lazy(() =>
  import(
    "./containers/corporate-relations-placement-travel-and-accommodation/TravelAndAccommodation"
  )
);
const IsepAboutUs = React.lazy(() =>
  import("./containers/MicroWebsite/isep-about-us/IsepAboutUs")
);
const BoardofGovernors = React.lazy(() =>
  import("./containers/board-of-governors/BoardofGovernors")
);
const AluminiSpeak = React.lazy(() =>
  import("./containers/alumini-speak/AluminiSpeak")
);
const VirtualInternship = React.lazy(() =>
  import("./containers/virtual-internship/VirtualInternship")
);
const SocialEntrepreneurship = React.lazy(() =>
  import(
    "./containers/MicroWebsite/isep-microwebsite/international-reach/SocialEntrepreneurship"
  )
);
const CoreFaculty = React.lazy(() =>
  import("./containers/core-faculty/CoreFaculty")
);
const AlumniCommittee = React.lazy(() =>
  import("./containers/MicroWebsite/alumni-committee/AlumniCommittee")
);
const LongTermCourses = React.lazy(() =>
  import("./containers/MicroWebsite/addmission/LongTermCourses")
);
const ShortTermCourses = React.lazy(() =>
  import("./containers/MicroWebsite/addmission/ShortTermCourses")
);
const VpnbHome = React.lazy(() =>
  import("./containers/MicroWebsite/vpnb/VpnbHome")
);
const Overview = React.lazy(() =>
  import("./containers/MicroWebsite/overview/Overview")
);
const CoreFacultyDetails = React.lazy(() =>
  import("./containers/core-faculty-details/CoreFacultyDetails")
);
const VirtualInteractiveLearning = React.lazy(() =>
  import(
    "./containers/MicroWebsite/virtual-interactive-learning/VirtualInteractiveLearning"
  )
);
const AdvancedFinancialManagement = React.lazy(() =>
  import(
    "./containers/MicroWebsite/advanced-financial-management/AdvancedFinancialManagement"
  )
);
const PlacementCommitteeGm = React.lazy(() =>
  import("./containers/placement-committee-gm/PlacementCommitteeGm")
);
const PlacementCommitteeBmHrm = React.lazy(() =>
  import("./containers/placement-committee-bm-hrm/PlacementCommitteeBmHrm")
);
const GuestLectureSeries = React.lazy(() =>
  import("./containers/guest-lecture-series/GuestLectureSeries")
);
const GuestLectureSeriesmain = React.lazy(() =>
  import("./containers/guest-lecture-series/GuestLectureSeriesmain")
);
const GuestLectureSeriesXol = React.lazy(() =>
  import("./containers/guest-lecture-series-xol/GuestLectureaSeriesXol")
);
const XlForumSeries = React.lazy(() =>
  import("./containers/xl-forum-series/XlForumSeries")
);
const SchoolOfBusiness = React.lazy(() =>
  import(
    "./containers/acedemic-programmmes-school-of-business/SchoolOfBusiness"
  )
);
const AnnualAlumniMeet = React.lazy(() =>
  import("./containers/annual-alumni-meet/AnnualAlumniMeet")
);
const ContactUs = React.lazy(() => import("./containers/contact-us/ContactUs"));
const IsepHome = React.lazy(() =>
  import("./containers/MicroWebsite/isep-home/IsepHome")
);
const AwardsForm = React.lazy(() =>
  import("./containers/MicroWebsite/awards-form/AwardsForm")
);
const CareerTransformationSeries = React.lazy(() =>
  import("./containers/career-transformation-series/CareerTransformationSeries")
);
const FacultyInnovationTeaching = React.lazy(() =>
  import(
    "./containers/faculty-research-innovation-in-teaching/FacultyInnovationTeaching"
  )
);
const OurRecruiters = React.lazy(() =>
  import(
    "./containers/corporate-relations-placement-our-recruiters/OurRecruiters"
  )
);
const OurRecruitersGm = React.lazy(() =>
  import(
    "./containers/corporate-relations-placement-gm-our-recruiters/OurRecruitersGm"
  )
);
const HowToCreateVisibility = React.lazy(() =>
  import(
    "./containers/corporate-relations-placement-how-to-create-visibility/HowToCreateVisibility"
  )
);
const LibraryCommittee = React.lazy(() =>
  import("./containers/MicroWebsite/library-committee/LibraryCommittee")
);
const LibraryPersonnels = React.lazy(() =>
  import("./containers/MicroWebsite/library-personnels/LibraryPersonnels")
);
const LibraryNewsletter = React.lazy(() =>
  import("./containers/MicroWebsite/library-newsletter/LibraryNewsletter")
);
const ManagementDevelopmentProgrammes = React.lazy(() =>
  import(
    "./containers/management-development-programmes/ManagementDevelopmentProgrammes"
  )
);
const CreateVisibility = React.lazy(() =>
  import("./containers/create-visibility/CreateVisibility")
);
const CurriculumVitae = React.lazy(() =>
  import("./containers/MicroWebsite/curriculam-vitae/CurriculumVitae")
);
const NewsRoomLinks = React.lazy(() =>
  import("./containers/news-room-links/NewsRoomLinks")
);

const UgcPage = React.lazy(() =>
  import("./containers/ugc/ugc")
);


const StudentCommittee = React.lazy(() =>
  import("./containers/student-committee/StudentCommittee")
);

const CentreEcologySustainability = React.lazy(() =>
  import(
    "./containers/centre-ecology-sustainability/CentreEcologySustainability"
  )
);
const FinancialMarketCentre = React.lazy(() =>
  import("./containers/financial-market-centre/FinancialMarketCentre")
);
const HumanResourceDevelopment = React.lazy(() =>
  import("./containers/human-resource-development/HumanResourceDevelopment")
);
const LogisticsSCM = React.lazy(() =>
  import("./containers/logistics-scm/LogisticsSCM")
);
const PeaceAndJustice = React.lazy(() =>
  import("./containers/peace-and-justice/PeaceAndJustice")
);
const ConvocationBrochures = React.lazy(() =>
  import("./containers/convocation-brochures/ConvocationBrochures")
);
const XLRIHomecoming = React.lazy(() =>
  import("./containers/xlri-homecoming/XLRIHomecoming")
);
const CelebratingExcellence = React.lazy(() =>
  import("./containers/celebrating-excellence/CelebratingExcellence")
);
const AnnualOrations = React.lazy(() =>
  import("./containers/annual-orations/AnnualOrations")
);
const BmHrmFaq = React.lazy(() =>
  import("./containers/corporate-relations-placement-bm-faq/BmHrmFaq")
);
const BatchProfile = React.lazy(() =>
  import(
    "./containers/corporate-relations-placement-batch-profile/BatchProfile"
  )
);
const CorporateRelationsPlacement = React.lazy(() =>
  import(
    "./containers/corporate-relations-placement/CorporateRelationsPlacement"
  )
);
const XatQuestionPapers = React.lazy(() =>
  import(
    "./containers/academic-programmes-xat-question-papers/XatQuestionPapers"
  )
);
const SocialInitiatives = React.lazy(() =>
  import("./containers/social-initiatives/SocialInitiatives")
);
const CentresOfExcellence = React.lazy(() =>
  import("./containers/centres-of-excellence/CentresOfExcellence")
);
const OurAcademicPrograms = React.lazy(() =>
  import("./containers/our-academic-programs/OurAcademicPrograms")
);
const SocialImpact = React.lazy(() =>
  import("./containers/social-impact/SocialImpact")
);
const Sustainability = React.lazy(() =>
  import("./containers/sustainability/Sustainability")
);
const XlriAwards = React.lazy(() =>
  import("./containers/MicroWebsite/xlri-awards/XlriAwards")
);
const Services = React.lazy(() =>
  import("./containers/MicroWebsite/services/Services")
);
const AwardWinners = React.lazy(() =>
  import("./containers/MicroWebsite/alumni-award-winners/AwardWinners")
);
const EventsCalendar = React.lazy(() =>
  import("./containers/events-calendar/EventsCalendar")
);
const NewsroomNews = React.lazy(() =>
  import("./containers/newsroom-news/NewsroomNews")
);
const Fulcrum = React.lazy(() => import("./containers/fulcrum/Fulcrum"));
const OutlookUnionBudget = React.lazy(() =>
  import("./containers/outlook-of-union-budget/OutlookUnionBudget")
);
const ManagementEducation = React.lazy(() =>
  import(
    "./containers/principles-for-responsible-management-education/ManagementEducation"
  )
);
const ProgrammesGlance = React.lazy(() =>
  import("./containers/programmes-at-a-glance/ProgrammesGlance")
);
const GmBatchProfile = React.lazy(() =>
  import(
    "./containers/corporate-relations-placement-gm-batch-rofile/GmBatchProfile"
  )
);
const PressReleasesDetailOne = React.lazy(() =>
  import("./containers/press-releases-detail/PressReleasesDetailOne")
);
const EventsCalendarDetail = React.lazy(() =>
  import("./containers/events-calendar-detail/EventsCalendarDetail")
);
const CareerApplyNowForm = React.lazy(() =>
  import("./containers/career-apply-now-form/CareerApplyNowForm")
);
const AlumniLatestNews = React.lazy(() =>
  import("./containers/MicroWebsite/alumni-latest-news/AlumniLatestNews")
);
const NewsroomMagisArchiveIssues = React.lazy(() =>
  import(
    "./containers/newsroom-magis-archive-issues/NewsroomMagisArchiveIssues"
  )
);
const ContactOnlineGrievance = React.lazy(() =>
  import("./containers/contact-online-grievance/ContactOnlineGrievance")
);
const ContactOnlineGrievances = React.lazy(() =>
  import("./containers/contact-online-grievance-1/ContactOnlineGrievances")
);
const Thankyou = React.lazy(() =>
  import("./containers/MicroWebsite/thank-you/Thankyou")
);
const Contact = React.lazy(() => import("./containers/contact/Contact"));
const HighlightsOfTheYear = React.lazy(() =>
  import("./containers/MicroWebsite/highlights-of-the-year/HighlightsOfTheYear")
);
const ContactXlri = React.lazy(() =>
  import("./containers/contact-xlri/ContactXlri")
);
const StudentLifeXlri = React.lazy(() =>
  import("./containers/MicroWebsite/student-life-at-xlri/StudentLifeXlri")
);
const Resource = React.lazy(() => import("./containers/resource/Resource"));
const LongTermCoursesVil = React.lazy(() =>
  import("./containers/MicroWebsite/courses/LongTermCoursesVil")
);
const ShortTermCoursesVil = React.lazy(() =>
  import("./containers/MicroWebsite/courses/ShortTermCoursesVil")
);
const ServicePartners = React.lazy(() =>
  import("./containers/MicroWebsite/service-partners/ServicePartners")
);
const StudentSocieties = React.lazy(() =>
  import("./containers/student-societies/StudentSocieties")
);
const Accreditations = React.lazy(() =>
  import("./containers/newsroom-accreditations/Accreditations")
);
const XlriStaff = React.lazy(() => import("./containers/xlri-staff/XlriStaff"));
const AlumniXlriHome = React.lazy(() =>
  import("./containers/MicroWebsite/alumni-xlri-home/AlumniXlriHome")
);
const Scholarships = React.lazy(() =>
  import("./containers/MicroWebsite/scholarships/Scholarships")
);
const ReachingXlri = React.lazy(() =>
  import("./containers/reaching-xlri/ReachingXlri")
);
const CurrentContents = React.lazy(() =>
  import("./containers/MicroWebsite/current-contents/CurrentContents")
);
const AdmissionProcedure = React.lazy(() =>
  import("./containers/academic-programmes-overview/AdmissionProcedure")
);
const LeadershipAdministration = React.lazy(() =>
  import("./containers/leadership-administration/LeadershipAdministration")
);
const RecruitAtXlri = React.lazy(() =>
  import("./containers/recruit-at-xlri/RecruitAtXlri")
);
const PlacementOverview = React.lazy(() =>
  import("./containers/placement-overview/PlacementOverview")
);
const SocialImpactCSRActivities = React.lazy(() =>
  import("./containers/social-Impact-csr-activities/SocialImpactCSRActivities")
);
const FacultyResearch = React.lazy(() =>
  import("./containers/faculty-research/FacultyResearch")
);
const CxoSeries = React.lazy(() => import("./containers/cxo-series/CxoSeries"));
const ProgrammesGlances = React.lazy(() =>
  import("./containers/programmes-glance/ProgrammesGlances")
);
const NewsroomImportantAnnouncements = React.lazy(() =>
  import(
    "./containers/newsroom-Important-announcements/NewsroomImportantAnnouncements"
  )
);
const XatBulletin = React.lazy(() =>
  import("./containers/xat-bulletin/XatBulletin")
);
const XlriOnlineLearning = React.lazy(() =>
  import("./containers/MicroWebsite/xlri-online-learning/XlriOnlineLearning")
);
const CurrentContentsCv = React.lazy(() =>
  import("./containers/MicroWebsite/faculty-opening-apply/CurrentContentsCv")
);
const NewsroomMagis = React.lazy(() =>
  import("./containers/newsroom-magis/NewsroomMagis")
);
const TicketDetails = React.lazy(() =>
  import(
    "./containers/MicroWebsite/online-grievance-ticket-details/TicketDetails"
  )
);
const LibraryHome = React.lazy(() =>
  import("./containers/MicroWebsite/library-home/LibraryHome")
);
const LibraryLatestNew = React.lazy(() =>
  import("./containers/MicroWebsite/library-home/LibraryLatestNews")
);

const CourseDetailPage = React.lazy(() =>
  import("./containers/MicroWebsite/course-detail-page/CourseDetailPage")
);
const SchoolOfBusinessAcademic = React.lazy(() =>
  import("./containers/school-business-hrm/SchoolOfBusinessAcademic")
);
const SchoolOfBusinessAcademics = React.lazy(() =>
  import("./containers/school-business-bm/SchoolOfBusinessAcademics")
);
const SummerInternshipPlacements = React.lazy(() =>
  import("./containers/summer-internship-placements/SummerInternshipPlacements")
);
const BatchProfiles = React.lazy(() =>
  import("./containers/batch-profile-gm/BatchProfiles")
);
const ScrollToTop = React.lazy(() => import("../src/containers/ScrollTop"));
const CasestudyIndex = React.lazy(() =>
  import("./containers/CaseCentre/CasestudyIndex")
);
const CaseDetail = React.lazy(() =>
  import("./containers/CaseCentre/casestudy-casedetail/CaseDetail")
);

const XolCourseDetails = React.lazy(() =>
  import("./containers/MicroWebsite/xol-course-details/XolPgdbm")
);
const Donation = React.lazy(() => import("./containers/donation/Donation"));
const DonationCopy = React.lazy(() =>
  import("./containers/donation-copy/Donation")
);
const StudentSocietiesOne = React.lazy(() =>
  import("./containers/student-societies-extra-curricular/StudentSocietiesOne")
);
const StudentSocietiesTwo = React.lazy(() =>
  import(
    "./containers/student-societies-academic-committee/StudentSocietiesTwo"
  )
);
const StudentSocietiesThree = React.lazy(() =>
  import("./containers/student-societies-valhalla/StudentSocietiesThree")
);
const StudentSocietiesFour = React.lazy(() =>
  import("./containers/student-affairs-council/StudentSocietiesFour")
);
const StudentSocietiesFive = React.lazy(() =>
  import("./containers/student-societies-spicmacay/StudentSocietiesFive")
);
const StudentSocietiesSix = React.lazy(() =>
  import(
    "./containers/student-societies-academic-committee-1/StudentSocietiesSix"
  )
);
const StudentSocietiesSport = React.lazy(() =>
  import("./containers/student-societies-sports-com/StudentSocietiesSport")
);
const StudentSocietiesSportOne = React.lazy(() =>
  import("./containers/student-societies-sports-com-1/StudentSocietiesSportOne")
);
const StudentSocietiesDracula = React.lazy(() =>
  import(
    "./containers/student-societies-extra-curricular-dracula/StudentSocietiesDracula"
  )
);
const StudentSocietiesCoCurricular = React.lazy(() =>
  import(
    "./containers/student-societies-co-curricular-axiom/StudentSocietiesCoCurricular"
  )
);
const StudentSocietiesCoCurricularOne = React.lazy(() =>
  import(
    "./containers/student-societies-co-curricular-crux/StudentSocietiesCoCurricularOne"
  )
);
const NewsroomLibrary = React.lazy(() =>
  import("./containers/MicroWebsite/newsroom-detail-page/NewsroomLibrary")
);
const NewsroomSeriMonth = React.lazy(() =>
  import("./containers/MicroWebsite/newsroom-detail-page/NewsroomSeriMonth")
);
const NewsroomTraining = React.lazy(() =>
  import("./containers/MicroWebsite/newsroom-detail-page/NewsroomTraining")
);
const EventCalendarIngenium = React.lazy(() =>
  import("./containers/events-calendar-detail/EventCalendarIngenium")
);
const ExecutiveFellowProgrammes = React.lazy(() =>
  import("./containers/executive-fellow-programmes/ExecutiveFellowProgrammes")
);
const Home5s = React.lazy(() =>
  import("./containers/MicroWebsite/microwebsite-5s/home-5s/Home5s")
);
const CourseDetailPageOne = React.lazy(() =>
  import("./containers/MicroWebsite/course-detail-page/CourseDetailPageOne")
);
const CourseDetailPageTwo = React.lazy(() =>
  import("./containers/MicroWebsite/course-detail-page/CourseDetailPageTwo")
);
const CourseDetailPageThree = React.lazy(() =>
  import("./containers/MicroWebsite/course-detail-page/CourseDetailPageThree")
);
const CourseDetailpageFour = React.lazy(() =>
  import("./containers/MicroWebsite/course-detail-page/CourseDetailpageFour")
);
const CourseDetailPageFive = React.lazy(() =>
  import("./containers/MicroWebsite/course-detail-page/CourseDetailPageFive")
);
const CourseDetailPageSix = React.lazy(() =>
  import("./containers/MicroWebsite/course-detail-page/CourseDetailPageSix")
);
const CourseDetailPageSeven = React.lazy(() =>
  import("./containers/MicroWebsite/course-detail-page/CourseDetailPageSeven")
);
const CourseDetailPageEight = React.lazy(() =>
  import("./containers/MicroWebsite/course-detail-page/CourseDetailPageEight")
);
const CourseDetailPageNine = React.lazy(() =>
  import("./containers/MicroWebsite/course-detail-page/CourseDetailPageNine")
);
const CourseDetailPageTen = React.lazy(() =>
  import("./containers/MicroWebsite/course-detail-page/CourseDetailPageTen")
);
const CourseDetailPageEleven = React.lazy(() =>
  import("./containers/MicroWebsite/course-detail-page/CourseDetailPageEleven")
);
const CourseDetailPageTwelve = React.lazy(() =>
  import("./containers/MicroWebsite/course-detail-page/CourseDetailPageTwelve")
);
const CourseDetailPageThirteen = React.lazy(() =>
  import(
    "./containers/MicroWebsite/course-detail-page/CourseDetailPageThirteen"
  )
);
const CourseDetailPageFourteen = React.lazy(() =>
  import(
    "./containers/MicroWebsite/course-detail-page/CourseDetailPageFourteen"
  )
);
const CourseDetailPageFifteen = React.lazy(() =>
  import("./containers/MicroWebsite/course-detail-page/CourseDetailPageFifteen")
);
const CourseDetailPageSixteen = React.lazy(() =>
  import("./containers/MicroWebsite/course-detail-page/CourseDetailPageSixteen")
);
const HrAnalytics = React.lazy(() =>
  import("./containers/MicroWebsite/courses/short-term-courses/HrAnalytics")
);
const FinancialManagement = React.lazy(() =>
  import(
    "./containers/MicroWebsite/courses/short-term-courses/FinancialManagement"
  )
);
const AdvancedBusinessStrategies = React.lazy(() =>
  import(
    "./containers/MicroWebsite/courses/long-term-courses/diploma-advanced-business-strategies"
  )
);
const HumanresourceDevelopment = React.lazy(() =>
  import(
    "./containers/MicroWebsite/courses/short-term-courses/HumanresourceDevelopment"
  )
);
const LeadershipSalesMarketing = React.lazy(() =>
  import(
    "./containers/MicroWebsite/courses/short-term-courses/LeadershipSalesMarketing"
  )
);
const StrategicDecisionMaking = React.lazy(() =>
  import(
    "./containers/MicroWebsite/courses/short-term-courses/StrategicDecisionMaking"
  )
);
const FinancialAnalytics = React.lazy(() =>
  import(
    "./containers/MicroWebsite/courses/short-term-courses/FinancialAnalytics"
  )
);
const DigitalMarketing = React.lazy(() =>
  import(
    "./containers/MicroWebsite/courses/short-term-courses/DigitalMarketing"
  )
);
const HrmWithShrm = React.lazy(() =>
  import("./containers/MicroWebsite/courses/short-term-courses/HrmWithShrm")
);
const LeadershipChangeManagement = React.lazy(() =>
  import(
    "./containers/MicroWebsite/courses/short-term-courses/LeadershipChangeManagement"
  )
);
const DataScience = React.lazy(() =>
  import("./containers/MicroWebsite/courses/short-term-courses/DataScience")
);
const BlockChain = React.lazy(() =>
  import("./containers/MicroWebsite/courses/short-term-courses/BlockChain")
);
const ProductManagement = React.lazy(() =>
  import(
    "./containers/MicroWebsite/courses/short-term-courses/ProductManagement"
  )
);
const HumanResourceManagement = React.lazy(() =>
  import(
    "./containers/MicroWebsite/courses/long-term-courses/certificate-human-resource-management"
  )
);
const BusinessAnalytics = React.lazy(() =>
  import(
    "./containers/MicroWebsite/courses/long-term-courses/certification-business-analytics"
  )
);
const BusinessManangement = React.lazy(() =>
  import(
    "./containers/MicroWebsite/courses/long-term-courses/certificate-business-manangement"
  )
);
const SeniorLeadership = React.lazy(() =>
  import(
    "./containers/MicroWebsite/courses/long-term-courses/certificate-senior-leadership"
  )
);
const CertificateFinance = React.lazy(() =>
  import(
    "./containers/MicroWebsite/courses/long-term-courses/certificate-finance"
  )
);
const HumanCapitalLeadership = React.lazy(() =>
  import(
    "./containers/MicroWebsite/courses/long-term-courses/certificate-human-capital-leadership"
  )
);
const XolConclave = React.lazy(() =>
  import("./containers/MicroWebsite/xol-conclave/XolConclave")
);
const IsoCertificate = React.lazy(() =>
  import("./containers/MicroWebsite/library-home/IsoCertificate")
);
const HelpLinks = React.lazy(() =>
  import("./containers/MicroWebsite/library-home/HelpLinks")
);
const AceMutualFund = React.lazy(() =>
  import("./containers/MicroWebsite/library-home/AceMutualFund")
);
const AboutLibraryJournals = React.lazy(() =>
  import(
    "./containers/MicroWebsite/about-library-journals/AboutLibraryJournals"
  )
);

const AboutLibraryJournalsList = React.lazy(() =>
  import(
    "./containers/MicroWebsite/about-library-journal-list/AboutLibraryJournalList"
  )
);

const AboutLibraryBooks = React.lazy(() =>
  import("./containers/MicroWebsite/about-library-books/AboutLibraryBooks")
);
const AboutLibraryOpenAccess = React.lazy(() =>
  import(
    "./containers/MicroWebsite/about-library-open-access/AboutLibraryOpenAccess"
  )
);
const AboutLibraryLearning = React.lazy(() =>
  import(
    "./containers/MicroWebsite/about-library-learning/AboutLibraryLearning"
  )
);
const AboutLibraryTools = React.lazy(() =>
  import("./containers/MicroWebsite/about-library-tools/AboutLibraryTools")
);
const AboutLibraryRepository = React.lazy(() =>
  import("./containers/MicroWebsite/about-library-repository/AboutLibraryRepository")
);
const AlumniGallery = React.lazy(() =>
  import("./containers/MicroWebsite/alumni-gallery/AlumniGallery")
);
const XolStudentCommittee = React.lazy(() =>
  import("./containers/MicroWebsite/xol-student-committe/XolStudentCommittee")
);
const CentreSpirituality = React.lazy(() =>
  import("./containers/centre-spirituality/CentreSpirituality")
);
const CentreGlobalStudies = React.lazy(() =>
  import("./containers/centres-global-south-studies/CentreGlobalStudies")
);
const CenterEventCalender = React.lazy(() =>
  import("./containers/centres-global-south-studies/EventCalenderAll"));
const KnowledgeHub = React.lazy(() =>
  import("./containers/centres-global-south-studies/KnowledgeHubPopup")
);
const CenterEventsCalendarDetail = React.lazy(() =>
  import("./containers/centres-global-south-studies/EventCalendarDetails")
);
const DebateOnBusinessEthics = React.lazy(() =>
  import("./containers/tata-foundation/competitions/DebateOnBusinessEthics")
);
const EssayWritingCompetition = React.lazy(() =>
  import("./containers/tata-foundation/competitions/EssayWritingCompetition")
);
const PosterCompetition = React.lazy(() =>
  import("./containers/tata-foundation/competitions/PosterCompetition")
);
const LifeCampus = React.lazy(() =>
  import(
    "./containers/MicroWebsite/isep-microwebsite/life-on-campus/LifeCampus"
  )
);
const StudentSocietiesAxiom = React.lazy(() =>
  import(
    "./containers/student-societies-informal-committee/StudentSocietiesaxiom"
  )
);
const BatchProfileXol = React.lazy(() =>
  import("./containers/MicroWebsite/xol-batch-profile/BatchProfile")
);
const CentrePublicPolicy = React.lazy(() =>
  import("./containers/centre-public-policy/CentrePublicPolicy")
);
const CentreEntrepreneurship = React.lazy(() =>
  import("./containers/centre-entrepreneurship/CentreEntrepreneurship")
);
const CxoClub = React.lazy(() => import("./containers/cxo-club/CxoClub"));
const CasGmBatchProfile = React.lazy(() =>
  import(
    "./containers/career-advisory-services-gm-batch-profile/CasGmBatchProfile"
  )
);
const XolPgdbm = React.lazy(() =>
  import("./containers/MicroWebsite/xol-course-details/XolPgdbm")
);
const XolPgdHrm = React.lazy(() =>
  import("./containers/MicroWebsite/xol-course-details/XolPgdHrm")
);
const XolPgdf = React.lazy(() =>
  import("./containers/MicroWebsite/xol-course-details/XolPgdf")
);
const MohanReddyChatInterview = React.lazy(() =>
  import(
    "./containers/corporate-relations-placement-fire-chat-interview/MohanReddyChatInterview"
  )
);
const HarshChatInterview = React.lazy(() =>
  import(
    "./containers/corporate-relations-placement-fire-chat-interview/HarshChatInterview"
  )
);
const GopalKrishnaChatInterview = React.lazy(() =>
  import(
    "./containers/corporate-relations-placement-fire-chat-interview/GopalKrishnaChatInterview"
  )
);
const GauravChatInterview = React.lazy(() =>
  import(
    "./containers/corporate-relations-placement-fire-chat-interview/GauravChatInterview"
  )
);
const Avensis = React.lazy(() =>
  import("./containers/conclave-seminars-panel-discussion/Avensis")
);
const EVartan = React.lazy(() =>
  import("./containers/conclave-seminars-panel-discussion/EVartan")
);
const Kronos = React.lazy(() =>
  import("./containers/conclave-seminars-panel-discussion/Kronos")
);
const RoundTableDiscussion = React.lazy(() =>
  import("./containers/conclave-seminars-panel-discussion/RoundTableDiscussion")
);
const ReEnvision = React.lazy(() =>
  import("./containers/conclave-seminars-panel-discussion/ReEnvision")
);
const Ingenium = React.lazy(() =>
  import("./containers/conclave-seminars-panel-discussion/Ingenium")
);
const Clockspeed = React.lazy(() =>
  import("./containers/conclave-seminars-panel-discussion/Clockspeed")
);
const WomenRoundtableConclave = React.lazy(() =>
  import(
    "./containers/conclave-seminars-panel-discussion/WomenRoundtableConclave"
  )
);
const DoubleMastersPrgm = React.lazy(() =>
  import("./containers/double-masters-programme/lscmAnalytics")
);
const FaqDoubleMasters = React.lazy(() =>
  import("./containers/double-masters-programme-faq/FaqDoubleMasters")
);
const XlSamvaad = React.lazy(() =>
  import("./containers/conclave-seminars-panel-discussion/XlSamvaad")
);
const studentProfilePgdmGm = React.lazy(() =>
  import("./containers/student-profile/student-profile-pgdm-gm")
);
const studentProfilePgdmlscm = React.lazy(() =>
  import("./containers/pgdmlscm/studentProfile/studentProfilePgdmlscm")
);
const studentProfileDetails = React.lazy(() =>
  import("./containers/pgdmlscm/studentProfile/studentProfileDetails")
);
const BatchProfilePgdmLscm = React.lazy(() =>
  import("./containers/pgdmlscm/batchProfilelscm")
);
const PlacementCommitteePgdmLscm = React.lazy(() =>
  import("./containers/pgdmlscm/PlacementCommitte")
);
const OurRecruitersLscm = React.lazy(() =>
  import("./containers/pgdmlscm/OurRecruiters")
);
const StudentPlacementCommitteLscm = React.lazy(() =>
  import("./containers/pgdmlscm/StudentPlacementCommittee")
);
const Loader = React.lazy(() => import("./components/loader/Loader"));
const SupportStaff = React.lazy(() =>
  import("./containers/support-staff/SupportStaff")
);
const SupportStaffTeaching = React.lazy(() =>
  import("./containers/support-staff/SupportStaffTeaching")
);
const CourseDetail = React.lazy(() =>
  import("./containers/MicroWebsite/courses/short-term-courses/CourseDetail")
);
const CourseDetails = React.lazy(() =>
  import("./containers/MicroWebsite/courses/long-term-courses/CourseDetails")
);
const PrivacyPolicy = React.lazy(() =>
  import("./containers/privacy-policy/PrivacyPolicy")
);
const DetailPage = React.lazy(() =>
  import("./containers/conclave-seminars-panel-discussion/DetailPage")
);
const Nirf = React.lazy(() => import("./containers/newsroom-nirf/Nirf"));
const Aicte = React.lazy(() => import("./containers/newsroom-aicte/Aicte"));
const Fcra = React.lazy(() => import("./containers/newsroom-aicte/Fcra"));
const LatestNewsDetail = React.lazy(() =>
  import(
    "./containers/MicroWebsite/alumni-latest-news/alumni-latest-news-detail/LatestNewsDetail"
  )
);
const CourseDetailXol = React.lazy(() =>
  import("./containers/MicroWebsite/xol-course-details/CourseDetail")
);
const AnnouncementDetails = React.lazy(() =>
  import("./containers/newsroom-Important-announcements/Details")
);
const TermsAndConditions = React.lazy(() =>
  import("./containers/terms-and-conditions/TermsAndConditions")
);
const MandatoryDisclosure = React.lazy(() =>
  import("./containers/aboutus-mandatory-disclosure/MandatoryDisclosure")
);
const XolConclaves = React.lazy(() =>
  import("./containers/MicroWebsite/xol-conclave/XolConclaves")
);
const XolConclaveDetailPage = React.lazy(() =>
  import("./containers/MicroWebsite/xol-conclave/XolConclaveDetailPage")
);
const PlacementBrochure = React.lazy(() =>
  import("./containers/placement-brochure/PlacementBrochure")
);
const AccreditationNew = React.lazy(() =>
  import("./containers/newsroom-accreditations/AccreditationNew")
);
const EfficientElements = React.lazy(() =>
  import("./containers/library-efficient-elements/EfficientElements")
);
const ThinkCell = React.lazy(() =>
  import("./containers/library-think-cell/ThinkCell")
);
const Coursera = React.lazy(() =>
  import("./containers/library-coursera/Coursera")
);
const AcademicSupport = React.lazy(() =>
  import("./containers/library-academic-support/AcademicSupport")
);
const BookTakeAway = React.lazy(() =>
  import("./containers/library-book-takeaway/BookTakeAway")
);
const ResearchSupport = React.lazy(() =>
  import("./containers/library-research-support/ResearchSupport")
);
const DissertationsTable = React.lazy(() =>
  import("./containers/MicroWebsite/about-library-catalogue/DissertationsTable")
);
const NewsletterView = React.lazy(() =>
  import("./containers/MicroWebsite/library-home/NewsletterView")
);
const NewCourseDeatailPage = React.lazy(() =>
  import("./containers/MicroWebsite/new-course-buy-page/NewCourseDeatailPage")
);
const NewForm = React.lazy(() => import("./containers/new-form/NewForm"));
const CourseDetailPageMdp = React.lazy(() =>
  import("./containers/mdp-course-detail/CourseDetailPage")
);
const StudentTestimonials = React.lazy(() =>
  import("./components/HomePageStudentTestimonial/StudentTestimonials")
);
const News5sDetail = React.lazy(() =>
  import("./containers/MicroWebsite/microwebsite-5s/news-5s/NewsEventDetail")
);
const vpnbService = React.lazy(() =>
  import("./containers/MicroWebsite/vpnb/vpnb-services/vpnbService")
);
const VpnbProcess = React.lazy(() =>
  import("./containers/MicroWebsite/vpnb/vpnb-process/VpnbProcess")
);
const VpnbContactUs = React.lazy(() =>
  import("./containers/MicroWebsite/vpnb/vpnb-contactUs/VpnbContactUs")
);
const VpnbFaqs = React.lazy(() =>
  import("./containers/MicroWebsite/vpnb/vpnb-faqs/VpnbFaqs")
);
const Commitee5s = React.lazy(() =>
  import("./containers/MicroWebsite/microwebsite-5s/committee/Commitee5")
);
const BestPractices5s = React.lazy(() =>
  import(
    "./containers/MicroWebsite/microwebsite-5s/best-practices/BestPractices"
  )
);
const BestPractice5s = React.lazy(() =>
  import(
    "./containers/MicroWebsite/microwebsite-5s/best-practices-5s/BestPractices5s"
  )
);
const ThankyouNew = React.lazy(() => import("./containers/thankyou/thankyou"));
const ThankyouForm = React.lazy(() =>
  import("./containers/thankyou/thankyouForm")
);
const PrivacyPolicyApp = React.lazy(() =>
  import("./containers/privacy-policy-app/PrivacyPolicy")
);
const TermsAndConditionsApp = React.lazy(() =>
  import("./containers/terms-n-conditions/TermsAndConditions")
);
const YoutubeLive = React.lazy(() =>
  import("./containers/youtube-live/YoutubeLive")
);
const CustomForm = React.lazy(() =>
  import("./containers/custom-forms/CustomForm")
);
const EresourceCenter = React.lazy(() =>
  import("./containers/MicroWebsite/library-eresource-center/eResourceCenter")
);
const LibraryDiscussionRoom = React.lazy(() =>
  import("./containers/libray-discussion-room/LibraryDiscussionRoom")
);
const NewsletterViewer = React.lazy(() =>
  import("./containers/MicroWebsite/library-newsletter-viewer/NewsletterViewer")
);
const Newsletter = React.lazy(() =>
  import("./containers/MicroWebsite/alumni-xlri-home/NewsLetter")
);
const NewsletterMagis = React.lazy(() =>
  import("./containers/newsroom-magis-archive-issues/NewsLetter")
);
const FlipBookPage = React.lazy(() =>
  import("./containers/xlri-homecoming/FlipbookPage")
);
const FlipBookConvocation = React.lazy(() =>
  import("./containers/convocation-brochures/FlipbookPage")
);
const CenterSkillDevelopment = React.lazy(() =>
  import("./containers/center-skill-development/CenterSkillDevelopment")
);
const AdjunctFaculty = React.lazy(() =>
  import("./containers/faculty-research-adjunct-faculty/AdjunctFaculty")
);

const DetailPageAdjunct = React.lazy(() =>
  import("./containers/faculty-research-adjunct-faculty/DetailPage")
);

const AdmissionInformation = React.lazy(() =>
  import("./containers/Admission-information/AdmissionInformation")
);

const LibaryLatestNews = React.lazy(() =>
  import("./containers/MicroWebsite/library-home/NewsDetail/LibaryLatestNews")
);

const XolStudentProfile = React.lazy(()=>
  import('./containers/xol-student-profile/xol-student-profile')
)

const HomePage = React.lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./containers/HomePage/Index")), 2000);
  });
});

function App() {
  // const client = new ApolloClient({
  //   uri: process.env.REACT_APP_GRAPHQL_ADMIN,
  //   headers:{
  //     Authorization: TOKEN
  //   },
  //   cache: new InMemoryCache(),
  // });

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const dispatch = useDispatch();
  const apolloClientRef = useSelector((state) => state.cache.apollo);
  const { token, uid } = getToken();
  const authId = useSelector((state) => state.auth.uid);

  const onForbidden = (message = "Session Expired!") => {
    toast.warn(message);
    dispatch(logoutUser(apolloClientRef));
  };

  const TOKEN = process.env.REACT_APP_TOKEN;

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([
      errorLink(onForbidden),
      directionalLink(TOKEN, authId),
    ]),
  });

  useEffect(() => {
    dispatch(setApolloClient(client));
    if (token) dispatch(loginUser({ token, uid }));
    else dispatch(logoutUser(apolloClientRef));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <MetaDataProvider>
      <SearchVisibilityProvider>
        <ApolloProvider client={client}>
          <ToastContainer />
          <BrowserRouter>
            <ScrollToTop />
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route
                  path="/"
                  exact
                  element={<Layout component={HomePage} />}
                />
                <Route path="/casestudy/*" element={<CasestudyIndex />} />
                <Route
                  path="casestudy/casedetail/:id"
                  element={<Layout component={CaseDetail} />}
                />
                <Route
                  path="/xol-course-details"
                  exact
                  element={<Layout component={XolCourseDetails} />}
                />
                {/* <Route
              path="/xlri-online-learning/pgdbm"
              exact
              element={<Layout component={XolPgdbm} />}
            /> */}
                {/* <Route
              path="/xlri-online-learning/pgdhrm"
              exact
              element={<Layout component={XolPgdHrm} />}
            /> */}
                {/* <Route
              path="/xlri-online-learning/pgdf"
              exact
              element={<Layout component={XolPgdf} />}
            /> */}
                <Route
                  path="/executive-education/vil/short-term-courses/:id"
                  exact
                  element={<Layout component={NewCourseDeatailPage} />}
                />
                <Route
                  path="/donation/fr-mcgrath"
                  exact
                  element={<Layout component={DonationCopy} />}
                />
                <Route
                  path="/donation"
                  exact
                  element={<Layout component={Donation} />}
                />
                {/* <Route path="/donation" exact element={<Donation />} /> */}
                <Route
                  path="/student-profile-pgdm-gm/:id"
                  exact
                  element={<Layout component={BatchProfiles} />}
                />
                <Route
                  path="/student-profile-pgdm-lscm/:id"
                  exact
                  element={<Layout component={studentProfileDetails} />}
                />
                <Route
                  path="/corporate-relations-and-placement/summer-internship-placements"
                  exact
                  element={<Layout component={SummerInternshipPlacements} />}
                />
                <Route
                  path="/academic-programmes/school-of-business/pgdbm"
                  exact
                  element={<Layout component={SchoolOfBusinessAcademics} />}
                />
                <Route
                  path="/academic-programmes/school-of-hrm/pgdm-hrm"
                  exact
                  element={<Layout component={SchoolOfBusinessAcademic} />}
                />
                <Route
                  path="/online-learning"
                  exact
                  element={<Layout component={XlriOnlineLearning} />}
                />
                <Route
                  path="/online-learning/:id"
                  exact
                  element={<Layout component={CourseDetailXol} />}
                />
                {/* <Route
              path="/xol-conclave"
              exact
              element={<Layout component={XolConclave} />}
            /> */}
                <Route
                  path="/xol-conclave"
                  exact
                  element={<Layout component={XolConclaves} />}
                />
                <Route
                  path="/xol-conclave/:id"
                  exact
                  element={<Layout component={XolConclaveDetailPage} />}
                />
                <Route
                  path="/xol-student-committee"
                  exact
                  element={<Layout component={XolStudentCommittee} />}
                />
                <Route
                  path="/academic-programmes/admission-procedure/xat-bulletin"
                  exact
                  element={<Layout component={XatBulletin} />}
                />
                <Route
                  path="/newsroom/important-announcements"
                  exact
                  element={
                    <Layout component={NewsroomImportantAnnouncements} />
                  }
                />
                <Route
                  path="/newsroom/important-announcements/:id"
                  exact
                  element={<Layout component={AnnouncementDetails} />}
                />
                <Route
                  path="/programmes-glance"
                  exact
                  element={<Layout component={ProgrammesGlances} />}
                />
                <Route
                  path="/corporate-relations-and-placement/cxo-series"
                  exact
                  element={<Layout component={CxoSeries} />}
                />
                <Route
                  path="/corporate-relations-and-placement/cxo-series/:id"
                  exact
                  element={<Layout component={FireChatInterview} />}
                />
                <Route
                  path="/faculty-research"
                  exact
                  element={<Layout component={FacultyResearch} />}
                />
                <Route
                  path="/about/social-engagement-activities"
                  exact
                  element={<Layout component={SocialImpactCSRActivities} />}
                />
                <Route
                  path="/placement-overview"
                  exact
                  element={<Layout component={PlacementOverview} />}
                />
                <Route
                  path="/corporate-relations-and-placement/recruit-at-xlri"
                  exact
                  element={<Layout component={RecruitAtXlri} />}
                />
                <Route
                  path="/about/leadership-administration"
                  exact
                  element={<Layout component={LeadershipAdministration} />}
                />
                <Route
                  path="/student-societies-functional-committee"
                  exact
                  element={<Layout component={StudentSocieties} />}
                />
                <Route
                  path="/pgdhrm-chro/thankyou"
                  exact
                  element={<Layout component={Thankyou} />}
                />
                <Route
                  path="/contact-online-grievance"
                  exact
                  element={<Layout component={ContactOnlineGrievance} />}
                />
                <Route
                  path="/contact-online-grievance-search"
                  exact
                  element={<Layout component={ContactOnlineGrievances} />}
                />
                <Route
                  path="/online-grievance-ticket-details"
                  exact
                  element={<Layout component={TicketDetails} />}
                />
                <Route
                  path="/newsroom/magis/archive-issues"
                  exact
                  element={<Layout component={NewsroomMagisArchiveIssues} />}
                />
                <Route
                  path="/alumni/latest-news"
                  exact
                  element={<Layout component={AlumniLatestNews} />}
                />
                <Route
                  path="/alumni/latest-news/:id"
                  exact
                  element={<Layout component={LatestNewsDetail} />}
                />
                <Route
                  path="/alumni/highlights-of-the-year"
                  exact
                  element={<Layout component={HighlightsOfTheYear} />}
                />
                <Route
                  path="/alumni/contact"
                  exact
                  element={<Layout component={ContactXlri} />}
                />
                <Route
                  path="/newsroom/events-calendar/:id"
                  exact
                  element={<Layout component={EventsCalendarDetail} />}
                />
                <Route
                  path="/newsroom/press-releases/:id"
                  exact
                  element={<Layout component={PressReleasesDetailOne} />}
                />
                <Route
                  path="/events-calendar-ingenium"
                  exact
                  element={<Layout component={EventCalendarIngenium} />}
                />
                <Route
                  path="/annual-orations"
                  exact
                  element={<Layout component={AnnualOrations} />}
                />
                <Route
                  path="/newsroom/celebrating-excellence"
                  exact
                  element={<Layout component={CelebratingExcellence} />}
                />
                <Route
                  path="/xlri-homecoming"
                  exact
                  element={<Layout component={XLRIHomecoming} />}
                />
                <Route
                  path="/xlri-homecoming/:id"
                  exact
                  element={<FlipBookPage />}
                />
                <Route
                  path="/convocation-brochures"
                  exact
                  element={<Layout component={ConvocationBrochures} />}
                />
                <Route
                  path="/convocation-brochures/:id"
                  exact
                  element={<FlipBookConvocation />}
                />
                <Route
                  path="/centres/centre-for-peace-and-justice"
                  exact
                  element={<Layout component={PeaceAndJustice} />}
                />
                <Route
                  path="/centres/centre-for-lscm"
                  exact
                  element={<Layout component={LogisticsSCM} />}
                />
                <Route
                  path="/centres/centre-for-hrd"
                  exact
                  element={<Layout component={HumanResourceDevelopment} />}
                />
                <Route
                  path="/centres/centre-for-financial-market"
                  exact
                  element={<Layout component={FinancialMarketCentre} />}
                />
                <Route
                  path="/centres/centre-for-faces"
                  exact
                  element={<Layout component={CentreEcologySustainability} />}
                />
                <Route
                  path="/centres/xlces"
                  exact
                  element={<Layout component={CentreSpirituality} />}
                />
                <Route
                  path="/centres/globalstudies"
                  exact
                  element={<Layout component={CentreGlobalStudies} />}
                />
                <Route
                  path="/centres/events-calendar"
                  exact
                  element={<Layout component={CenterEventCalender} />}
                />
                <Route
                  path="/centres/events-calendar/:id"
                  exact
                  element={<Layout component={CenterEventsCalendarDetail} />}
                />
                <Route
                  path="/centres/sdcell"
                  exact
                  element={<Layout component={CenterSkillDevelopment} />}
                />
                <Route
                  path="/centres/knowledgeHub/:id"
                  exact
                  element={<Layout component={KnowledgeHub} />}
                />
                {/* aboutus route */}
                <Route
                  path="/about"
                  exact
                  element={<Layout component={AboutUsPage} />}
                />
                <Route
                  path="/about/heritage"
                  exact
                  element={<Layout component={AboutUsHeritage} />}
                />
                <Route
                  path="/about/vision-mission"
                  element={<Layout component={MissionVission} />}
                />
                <Route
                  path="/about/about-the-director"
                  exact
                  element={<Layout component={AboutUsDirector} />}
                />
                <Route
                  path="/about/director-message"
                  exact
                  element={<Layout component={AboutusDirectorMessage} />}
                />
                <Route
                  path="/about/industry-advisory-councils"
                  exact
                  element={
                    <Layout component={AboutusIndustryAdvisoryCouncils} />
                  }
                />
                <Route
                  path="/about/xlri-ranking"
                  exact
                  element={<Layout component={AboutUsXlriRanking} />}
                />
                <Route
                  path="/about/full-time-faculty"
                  exact
                  element={<Layout component={CoreFaculty} />}
                />
                <Route
                  path="/about/programs-and-equivalence"
                  exact
                  element={<Layout component={ProgramsRecognition} />}
                />
                <Route
                  path="/about/our-brand-identity"
                  exact
                  element={<Layout component={OurBrandIdentity} />}
                />
                <Route
                  path="/about/jesuit-founding-fathers"
                  exact
                  element={<Layout component={AboutUsJesuitFoundingFathers} />}
                />
                <Route
                  path="/executive-education"
                  exact
                  element={<Layout component={ExecutiveEducation} />}
                />
                <Route
                  path="/about/visiting-faculty"
                  exact
                  element={<Layout component={VisitingFacultyMembers} />}
                />
                <Route
                  path="/about/adjunct-faculty"
                  exact
                  element={<Layout component={AdjunctFaculty} />}
                />
                <Route
                  path="/about/adjunct-faculty/:id"
                  exact
                  element={<Layout component={DetailPageAdjunct} />}
                />
                <Route
                  path="/faculty-research/research-publications"
                  exact
                  element={<Layout component={ResearchPublications} />}
                />
                <Route
                  path="/executive-education/consultancy-services"
                  exact
                  element={<Layout component={ConsultancyServices} />}
                />
                <Route
                  path="/executive-education/company-programmes"
                  exact
                  element={<Layout component={CompanyProgrammes} />}
                />
                <Route path="/faq" exact element={<Layout component={Faq} />} />
                <Route path="/faq/*" element={<Layout component={Faq} />} />
                <Route
                  path="/academic-programmes/school-of-research/fpm"
                  exact
                  element={<Layout component={FellowProgrammes} />}
                />
                <Route
                  path="/academic-programmes/school-of-research/executive-fpm"
                  exact
                  element={<Layout component={ExecutiveFellowProgrammes} />}
                />
                <Route
                  path="/corporate-relations-and-placement/placement-reports"
                  exact
                  element={<Layout component={PlacementReports} />}
                />
                <Route
                  path="/about/reserach-faculty-visiting"
                  exact
                  element={<Layout component={ResearchFacultyInnovation} />}
                />
                <Route
                  path="/corporate-relations-and-placement/conclaves-seminars-panel-discussion"
                  exact
                  element={<Layout component={SeminarsPanelDiscussion} />}
                />
                <Route
                  path="/corporate-relations-and-placement/conclaves-seminars-panel-discussion/:id"
                  exact
                  element={<Layout component={DetailPage} />}
                />
                <Route
                  path="/corporate-relations-and-placement/conclaves-seminars-panel-discussion/:id/:secondId"
                  exact
                  element={<Layout component={DetailPage} />}
                />
                <Route
                  path="/corporate-relations-and-placement/faq"
                  exact
                  element={<Layout component={Faqs} />}
                />
                <Route
                  path="/giving-to-xlri"
                  exact
                  element={<Layout component={GivingToXLRI} />}
                />
                <Route
                  path="/giving-to-xlri/endowment-fund"
                  exact
                  element={<Layout component={GivingToXLRI} />}
                />
                <Route
                  path="/giving-to-xlri/committee"
                  exact
                  element={<Layout component={GivingToXLRI} />}
                />
                <Route
                  path="/giving-to-xlri/future-plans"
                  exact
                  element={<Layout component={GivingToXLRI} />}
                />
                <Route
                  path="/corporate-relations-and-placement/student-placement-committee"
                  exact
                  element={<Layout component={PlacementCommittee} />}
                />
                {/* <Route
              path="/resources"
              exact
              element={<Layout component={Resources} />}
            /> */}
                <Route
                  path="/resources/it-infrastructure"
                  exact
                  element={<Layout component={Resources} />}
                />
                <Route
                  path="/resources/behavioural-lab"
                  exact
                  element={<Layout component={BehavioralLab} />}
                />
                <Route
                  path="/resources/sports-and-recreation"
                  exact
                  element={<Layout component={Resources} />}
                />
                <Route
                  path="/resources/food-courts"
                  exact
                  element={<Layout component={Resources} />}
                />
                <Route
                  path="/resources/auditoriums"
                  exact
                  element={<Layout component={Resources} />}
                />
                <Route
                  path="/resources/faculty-residents"
                  exact
                  element={<Layout component={Resources} />}
                />
                <Route
                  path="/resources/students-residents"
                  exact
                  element={<Layout component={Resources} />}
                />
                <Route
                  path="/resources/guest-accommodation"
                  exact
                  element={<Layout component={Resources} />}
                />
                <Route
                  path="/resources/gardens-and-parks"
                  exact
                  element={<Layout component={Resources} />}
                />
                <Route
                  path="/resources/mobility-with-in-campus"
                  exact
                  element={<Layout component={Resources} />}
                />
                <Route
                  path="/resources/classroom"
                  exact
                  element={<Layout component={Resources} />}
                />
                <Route
                  path="/resources/medical-facilities"
                  exact
                  element={<Layout component={Resources} />}
                />
                <Route
                  path="/resources/computer-center"
                  exact
                  element={<Layout component={Resources} />}
                />
                <Route
                  path="/resources/conference-halls"
                  exact
                  element={<Layout component={Resources} />}
                />
                <Route
                  path="/newsroom/gallery"
                  exact
                  element={<Layout component={Gallery} />}
                />
                <Route
                  path="/newsroom/gallery/video"
                  exact
                  element={<Layout component={Gallery} />}
                />
                <Route
                  path="/library/gallery"
                  exact
                  element={<Layout component={GalleryLibrary} />}
                />
                <Route
                  path="/corporate-relations-placement-fire-chat-interview"
                  exact
                  element={<Layout component={FireChatInterview} />}
                />
                <Route
                  path="/fire-chat-interview-padma-bvr-mohan-reddy"
                  exact
                  element={<Layout component={MohanReddyChatInterview} />}
                />
                <Route
                  path="/fire-chat-interview-harsh-mariwala"
                  exact
                  element={<Layout component={HarshChatInterview} />}
                />
                <Route
                  path="/fire-chat-interview-gopal-krishna"
                  exact
                  element={<Layout component={GopalKrishnaChatInterview} />}
                />
                <Route
                  path="/fire-chat-interview-gaurav-sharma"
                  exact
                  element={<Layout component={GauravChatInterview} />}
                />
                <Route
                  path="/corporate-relations-and-placement/recruitment-activities"
                  exact
                  element={<Layout component={RecruitmentActivities} />}
                />
                <Route
                  path="/home"
                  exact
                  element={<Layout component={Home} />}
                />
                <Route
                  path="/career-transformation"
                  exact
                  element={<Layout component={CareerTransformation} />}
                />
                <Route
                  path="/corporate-relations-and-placement/professional-mentorship-programme"
                  exact
                  element={<Layout component={ProfessionalMentorship} />}
                />
                <Route
                  path="/executive-education/vil/contact-us"
                  exact
                  element={<Layout component={ContactUs} />}
                />
                <Route
                  path="/5s/about"
                  exact
                  element={<Layout component={About} />}
                />
                <Route
                  path="/5s"
                  exact
                  element={<Layout component={Home5s} />}
                />
                <Route
                  path="/testimonials/:type"
                  exact
                  element={<Layout component={StudentTestimonials} />}
                />
                <Route
                  path="/isep/programmes"
                  exact
                  element={<Layout component={Programmes} />}
                />
                <Route
                  path="/international-reach"
                  exact
                  element={<Layout component={InternationalReach} />}
                />
                <Route
                  path="/isep/partners"
                  exact
                  element={<Layout component={InternationalReach} />}
                />
                <Route
                  path="/isep/social-entrepreneurship"
                  exact
                  element={<Layout component={SocialEntrepreneurship} />}
                />
                <Route
                  path="/testmonialmicro"
                  exact
                  element={<Layout component={TestmonialMicro} />}
                />
                <Route
                  path="/life-of-xlri"
                  exact
                  element={<Layout component={LifeXlri} />}
                />
                <Route
                  path="/committee-1"
                  exact
                  element={<Layout component={Commitee} />}
                />
                <Route
                  path="/committee-3"
                  exact
                  element={<Layout component={Commitee3} />}
                />
                <Route
                  path="/committee-2"
                  exact
                  element={<Layout component={Commitee2} />}
                />
                <Route
                  path="/committee-4"
                  exact
                  element={<Layout component={Commitee4} />}
                />
                <Route
                  path="/committee-5"
                  exact
                  element={<Layout component={Commitee5} />}
                />
                <Route
                  path="/5s/committee/:id"
                  exact
                  element={<Layout component={Commitee5s} />}
                />
                <Route
                  path="/5s/zonal-champions"
                  exact
                  element={<Layout component={ZonalChampions} />}
                />
                <Route
                  path="/5s/best-practices"
                  exact
                  element={<Layout component={BestPractices5s} />}
                />
                <Route
                  path="/5s/best-practices/:id"
                  exact
                  element={<Layout component={BestPractice5s} />}
                />
                {/* <Route
              path="/best-practices-sort"
              exact
              element={<Layout component={BestPracticesSort} />}
            />

            <Route
              path="/best-practices-shine"
              exact
              element={<Layout component={BestPracticesShine} />}
            />

            <Route
              path="/best-practices-sustain"
              exact
              element={<Layout component={BestPracticesSustain} />}
            /> */}
                <Route
                  path="/best-practices-set-in-order"
                  exact
                  element={<Layout component={BestPracticeSetInOrder} />}
                />
                <Route
                  path="/best-practices-standardize"
                  exact
                  element={<Layout component={BestPracticesStandardize} />}
                />
                <Route
                  path="/5s/get-involved"
                  exact
                  element={<Layout component={GetInvolved} />}
                />
                <Route
                  path="/satinfo"
                  exact
                  element={<Layout component={SatInfo} />}
                />
                <Route
                  path="/about/careers-opportunities"
                  exact
                  element={<Layout component={AboutusCareer} />}
                />
                <Route
                  path="/about/careers-opportunities/staff"
                  exact
                  element={<Layout component={AboutusCareer} />}
                />
                <Route
                  path="/about/careers-opportunities/faculty"
                  exact
                  element={<Layout component={AboutusCareer} />}
                />
                <Route
                  path="/aboutus-careerdetail/:id"
                  exact
                  element={<Layout component={AboutusCareerDetail} />}
                />
                <Route
                  path="/career-apply-now-form"
                  exact
                  element={<Layout component={CareerApplyNowForm} />}
                />
                <Route
                  path="/faculty-career-apply"
                  exact
                  element={<Layout component={NewForm} />}
                />
                <Route
                  path="/life-on-campus"
                  exact
                  element={<Layout component={LifeOnCampus} />}
                />
                <Route
                  path="/executive-education/edhrm-xlri"
                  exact
                  element={<Layout component={EdhrmXlri} />}
                />
                <Route
                  path="/newsroom-detail-page"
                  exact
                  element={<Layout component={NewsroomDetailPage} />}
                />
                <Route
                  path="/executive-education/vil/about-us"
                  exact
                  element={<Layout component={AboutUs} />}
                />
                <Route
                  path="/academic-programmes/school-of-business/pgdmiev"
                  exact
                  element={<Layout component={PostGraduateDiploma} />}
                />
                <Route
                  path="/isep/contact"
                  exact
                  element={<Layout component={IsepContactUs} />}
                />
                {/* <Route
                  path="/ace-mutual-funds"
                  exact
                  element={<Layout component={AceMutualFund} />}
                /> */}
                <Route
                  path="/library/catalogue"
                  exact
                  element={<Layout component={AboutLibraryCatalogue} />}
                />
                <Route
                  path="/library/eResource-center"
                  exact
                  element={<Layout component={EresourceCenter} />}
                />
                <Route
                  path="/library/discussion-room"
                  exact
                  element={<Layout component={LibraryDiscussionRoom} />}
                />
                <Route
                  path="/library/journals"
                  exact
                  element={<Layout component={AboutLibraryJournals} />}
                />
                <Route
                  path="/library/journals-list"
                  exact
                  element={<Layout component={AboutLibraryJournalsList} />}
                />
                <Route
                  path="/library/ebooks"
                  exact
                  element={<Layout component={AboutLibraryBooks} />}
                />
                <Route
                  path="/about-library-open-acess"
                  exact
                  element={<Layout component={AboutLibraryOpenAccess} />}
                />
                <Route
                  path="/library/help-links"
                  exact
                  element={<Layout component={HelpLinks} />}
                />
                <Route
                  path="/library/dissertation"
                  exact
                  element={<Layout component={Dissertations} />}
                />
                <Route
                  path="/library/dissertation/:id"
                  exact
                  element={<Layout component={DissertationsTable} />}
                />
                <Route
                  path="/library/news-papers"
                  exact
                  element={<Layout component={Newspapers} />}
                />
                <Route
                  path="/library/newsletter/:id"
                  exact
                  element={<NewsletterViewer />}
                />
                <Route
                  path="/library/magazines"
                  exact
                  element={<Layout component={Magazines} />}
                />
                <Route
                  path="/library/databases"
                  exact
                  element={<Layout component={AboutLibraryDatabases} />}
                />
                <Route
                  path="/library/elearning"
                  exact
                  element={<Layout component={AboutLibraryLearning} />}
                />
                <Route
                  path="/library/etools"
                  exact
                  element={<Layout component={AboutLibraryTools} />}
                />
                <Route
                  path="/library/eRepositories"
                  exact
                  element={<Layout component={AboutLibraryRepository} />}
                />
                <Route
                  path="/library/contactus"
                  exact
                  element={<Layout component={LibraryContactus} />}
                />
                <Route
                  path="/alumni-endowment-fund"
                  exact
                  element={<Layout component={AlumniEndowmentFund} />}
                />
                <Route
                  path="/library/about-library"
                  exact
                  element={<Layout component={AboutusLibraryCollection} />}
                />
                <Route
                  path="/library/about-library/collection-overview"
                  exact
                  element={<Layout component={AboutusLibraryCollection} />}
                />
                <Route
                  path="/library/about-library/circulation"
                  exact
                  element={<Layout component={AboutusLibraryCollection} />}
                />{" "}
                <Route
                  path="/library/about-library/general-rules"
                  exact
                  element={<Layout component={AboutusLibraryCollection} />}
                />
                <Route
                  path="/library/about-library/alumni-membership"
                  exact
                  element={<Layout component={AboutusLibraryCollection} />}
                />
                <Route
                  path="/5s/news"
                  exact
                  element={<Layout component={Newsroom} />}
                />
                <Route
                  path="/5s/news/:id"
                  exact
                  element={<Layout component={News5sDetail} />}
                />
                <Route
                  path="/corporate-relations-and-placement/travel-and-accommodation"
                  exact
                  element={<Layout component={TravelAndAccommodation} />}
                />
                <Route
                  path="/isep/about-us"
                  exact
                  element={<Layout component={IsepAboutUs} />}
                />
                <Route
                  path="/about/board-of-governors"
                  exact
                  element={<Layout component={BoardofGovernors} />}
                />
                <Route
                  path="/isep"
                  exact
                  element={<Layout component={IsepHome} />}
                />
                <Route
                  path="/alumni-committee"
                  exact
                  element={<Layout component={AlumniCommittee} />}
                />
                <Route
                  path="/executive-education/vil/admissions-short-term"
                  exact
                  element={<Layout component={ShortTermCourses} />}
                />
                <Route
                  path="/executive-education/vil/admissions-long-term"
                  exact
                  element={<Layout component={LongTermCourses} />}
                />
                <Route
                  path="/executive-education/vil/vpnb"
                  exact
                  element={<Layout component={VpnbHome} />}
                />
                <Route
                  path="/executive-education/vil/vpnb/services"
                  exact
                  element={<Layout component={vpnbService} />}
                />
                <Route
                  path="/executive-education/vil/vpnb/process"
                  exact
                  element={<Layout component={VpnbProcess} />}
                />
                <Route
                  path="/executive-education/vil/vpnb/contact-us"
                  exact
                  element={<Layout component={VpnbContactUs} />}
                />
                <Route
                  path="/executive-education/vil/vpnb/faqs"
                  exact
                  element={<Layout component={VpnbFaqs} />}
                />
                <Route
                  path="/pgdhrm-chro"
                  exact
                  element={<Layout component={Overview} />}
                />
                <Route
                  path="/about/full-time-faculty/:id"
                  exact
                  element={<Layout component={CoreFacultyDetails} />}
                />
                <Route
                  path="/placement-office-team-pgdm-gm"
                  exact
                  element={<Layout component={PlacementCommitteeGm} />}
                />
                <Route
                  path="/corporate-relations-and-placement/placement-office-team-bm-hrm"
                  exact
                  element={<Layout component={PlacementCommitteeBmHrm} />}
                />
                <Route
                  path="/corporate-relations-and-placement/guest-lecture-series"
                  exact
                  element={<Layout component={GuestLectureSeriesmain} />}
                />
                <Route
                  path="/guest-lecture-series-xol"
                  exact
                  element={<Layout component={GuestLectureSeriesXol} />}
                />
                {/* <Route
              path="/guest-lecture-seriesmain"
              exact
              element={<Layout component={GuestLectureSeriesmain} />}
            /> */}
                <Route
                  path="/xl-forum-series"
                  exact
                  element={<Layout component={XlForumSeries} />}
                />
                <Route
                  path="/academic-programmes/school-of-business/pgdgm"
                  exact
                  element={<Layout component={SchoolOfBusinessNew} />}
                />
                <Route
                  path="/annual-alumni-meet"
                  exact
                  element={<Layout component={AnnualAlumniMeet} />}
                />
                <Route
                  path="/centres/tata-foundation-in-business-ethics"
                  exact
                  element={<Layout component={TataFoundation} />}
                />
                <Route
                  path="/debate-competition-on-business-ethics"
                  exact
                  element={<Layout component={DebateOnBusinessEthics} />}
                />
                <Route
                  path="/all-india-essay-writing-competition"
                  exact
                  element={<Layout component={EssayWritingCompetition} />}
                />
                <Route
                  path="/all-india-poster-competition"
                  exact
                  element={<Layout component={PosterCompetition} />}
                />
                {/* <Route
                  path="/centres/centre-for-cgeil"
                  exact
                  element={<Layout component={GenderEquality} />}
                /> */}
                <Route
                  path="/executive-education/vil"
                  exact
                  element={<Layout component={VirtualInteractiveLearning} />}
                />
                <Route
                  path="/advanced-financial-management"
                  exact
                  element={<Layout component={AdvancedFinancialManagement} />}
                />
                <Route
                  path="/alumini-speak"
                  exact
                  element={<Layout component={AluminiSpeak} />}
                />
                <Route
                  path="/virtual-internship"
                  exact
                  element={<Layout component={VirtualInternship} />}
                />
                <Route
                  path="/awards-form"
                  exact
                  element={<Layout component={AwardsForm} />}
                />
                <Route
                  path="/career-transformation-series"
                  exact
                  element={<Layout component={CareerTransformationSeries} />}
                />
                <Route
                  path="/faculty-research/innovation-in-teaching"
                  exact
                  element={<Layout component={FacultyInnovationTeaching} />}
                />
                <Route
                  path="/corporate-relations-and-placement/our-recruiters"
                  exact
                  element={<Layout component={OurRecruiters} />}
                />
                <Route
                  path="/corporate-relations-and-placement/gm-our-recruiters"
                  exact
                  element={<Layout component={OurRecruitersGm} />}
                />
                <Route
                  path="/corporate-relations-and-placement/how-to-create-visibility"
                  exact
                  element={<Layout component={HowToCreateVisibility} />}
                />
                <Route
                  path="/corporate-relations-and-placement/recruitment-faq"
                  exact
                  element={<Layout component={BmHrmFaq} />}
                />
                <Route
                  path="/corporate-relations-and-placement/batch-profile"
                  exact
                  element={<Layout component={BatchProfile} />}
                />
                <Route
                  path="/corporate-relations-and-placement/pgdm-gm-batch-profile"
                  exact
                  element={<Layout component={CasGmBatchProfile} />}
                />
                <Route
                  path="/library/sjgl-committee"
                  exact
                  element={<Layout component={LibraryCommittee} />}
                />
                <Route
                  path="/library/sjgl-staff"
                  exact
                  element={<Layout component={LibraryPersonnels} />}
                />
                {/* <Route
              path="/library/newsletter"
              exact
              element={<Layout component={LibraryNewsletter} />}
            /> */}
                {/* <Route
              path="/library/newsletters/:id"
              exact
              element={<Layout component={NewsletterViewer} />}
            /> */}
                <Route
                  path="/executive-education/mdp"
                  exact
                  element={
                    <Layout component={ManagementDevelopmentProgrammes} />
                  }
                />
                <Route
                  path="/corporate-relations-and-placement/create-visibility-for-your-firm"
                  exact
                  element={<Layout component={CreateVisibility} />}
                />
                <Route
                  path="/curriculam-vitae"
                  exact
                  element={<Layout component={CurriculumVitae} />}
                />
                <Route
                  path="/newsroom"
                  exact
                  element={<Layout component={NewsRoomLinks} />}
                />
                <Route
                  path="/ugc"
                  exact
                  element={<Layout component={UgcPage} />}
                />
                <Route
                  path="/corporate-relations-and-placement/pgdm-gm-student-committee"
                  exact
                  element={<Layout component={StudentCommittee} />}
                />
                <Route
                  path="/corporate-relations-and-placement/pgdm-gm-student-committee/:id"
                  exact
                  element={<Layout component={StudentCommittee} />}
                />
                <Route
                  path="/corporate-relations-and-placement"
                  exact
                  element={<Layout component={CorporateRelationsPlacement} />}
                />
                <Route
                  path="/academic-programmes/xat-question-papers"
                  exact
                  element={<Layout component={XatQuestionPapers} />}
                />
                <Route
                  path="/about/social-initiatives"
                  exact
                  element={<Layout component={SocialInitiatives} />}
                />
                <Route
                  path="/centres"
                  exact
                  element={<Layout component={CentresOfExcellence} />}
                />
                <Route
                  path="/academic-programmes"
                  exact
                  element={<Layout component={OurAcademicPrograms} />}
                />
                <Route
                  path="/social-impact"
                  exact
                  element={<Layout component={SocialImpact} />}
                />
                <Route
                  path="/social-impact/cii-yi"
                  exact
                  element={<Layout component={SocialImpact} />}
                />
                <Route
                  path="/social-impact/samarthya"
                  exact
                  element={<Layout component={SocialImpact} />}
                />
                <Route
                  path="/social-impact/sigma"
                  exact
                  element={<Layout component={SocialImpact} />}
                />
                <Route
                  path="/about/sustainability"
                  exact
                  element={<Layout component={Sustainability} />}
                />
                <Route
                  path="/sustainability/power-of-sun"
                  exact
                  element={<Layout component={Sustainability} />}
                />
                <Route
                  path="/sustainability/biogas-plant"
                  exact
                  element={<Layout component={Sustainability} />}
                />
                <Route
                  path="/xlri-awards"
                  exact
                  element={<Layout component={XlriAwards} />}
                />
                <Route
                  path="/alumni/services"
                  exact
                  element={<Layout component={Services} />}
                />
                <Route
                  path="/alumni/award-winners"
                  exact
                  element={<Layout component={AwardWinners} />}
                />
                <Route
                  path="/newsroom/events-calendar"
                  exact
                  element={<Layout component={EventsCalendar} />}
                />
                <Route
                  path="/newsroom/news"
                  exact
                  element={<Layout component={NewsroomNews} />}
                />
                <Route
                  path="/newsroom/press-releases"
                  exact
                  element={<Layout component={NewsroomNews} />}
                />
                <Route
                  path="/newsroom/media-contact"
                  exact
                  element={<Layout component={NewsroomNews} />}
                />
                <Route
                  path="/contact"
                  exact
                  element={<Layout component={Contact} />}
                />
                <Route
                  path="/privacy-policy"
                  exact
                  element={<Layout component={PrivacyPolicy} />}
                />
                <Route
                  path="/terms-n-conditions"
                  exact
                  element={<Layout component={TermsAndConditions} />}
                />
                <Route
                  path="/fulcrum"
                  exact
                  element={<Layout component={Fulcrum} />}
                />
                <Route
                  path="/outlook-of-union-budget"
                  exact
                  element={<Layout component={OutlookUnionBudget} />}
                />
                <Route
                  path="/avensis"
                  exact
                  element={<Layout component={Avensis} />}
                />
                <Route
                  path="/e-vartan"
                  exact
                  element={<Layout component={EVartan} />}
                />
                <Route
                  path="/kronos"
                  exact
                  element={<Layout component={Kronos} />}
                />
                <Route
                  path="/ingenium-conclave"
                  exact
                  element={<Layout component={Ingenium} />}
                />
                <Route
                  path="/round-table-discussion"
                  exact
                  element={<Layout component={RoundTableDiscussion} />}
                />
                <Route
                  path="/reenvision"
                  exact
                  element={<Layout component={ReEnvision} />}
                />
                <Route
                  path="/xlsamvaad"
                  exact
                  element={<Layout component={XlSamvaad} />}
                />
                <Route
                  path="/clockspeed-conclave"
                  exact
                  element={<Layout component={Clockspeed} />}
                />
                <Route
                  path="/women-roundtable-conclave"
                  exact
                  element={<Layout component={WomenRoundtableConclave} />}
                />
                <Route
                  path="/about/principles-for-responsible-management-education"
                  exact
                  element={<Layout component={ManagementEducation} />}
                />
                <Route
                  path="/executive-education/mdp/programmes-at-a-glance"
                  exact
                  element={<Layout component={ProgrammesGlance} />}
                />
                <Route
                  path="/student-profile-pgdm-gm"
                  exact
                  element={<Layout component={studentProfilePgdmGm} />}
                />
                <Route
                  path="/student-life-at-xlri"
                  exact
                  element={<Layout component={StudentLifeXlri} />}
                />
                <Route
                  path="/student-profile-pgdm-lscm"
                  exact
                  element={<Layout component={studentProfilePgdmlscm} />}
                />
                <Route
                  path="/corporate-relations-and-placement/pgdm-lscm-batch-profile"
                  exact
                  element={<Layout component={BatchProfilePgdmLscm} />}
                />
                <Route
                  path="/placement-office-team-pgdm-lscm"
                  exact
                  element={<Layout component={PlacementCommitteePgdmLscm} />}
                />
                <Route
                  path="/corporate-relations-and-placement/lscm-our-recruiters"
                  exact
                  element={<Layout component={OurRecruitersLscm} />}
                />
                <Route
                  path="/corporate-relations-and-placement/lscm-student-placement-committee"
                  exact
                  element={<Layout component={StudentPlacementCommitteLscm} />}
                />
                <Route
                  path="/resources"
                  exact
                  element={<Layout component={Resource} />}
                />
                <Route
                  path="/executive-education/vil/long-term-courses"
                  exact
                  element={<Layout component={LongTermCoursesVil} />}
                />
                <Route
                  path="/executive-education/vil/long-term-courses/:id"
                  exact
                  element={<Layout component={CourseDetails} />}
                />
                <Route
                  path="/executive-education/vil/short-term-courses"
                  exact
                  element={<Layout component={ShortTermCoursesVil} />}
                />
                {/* <Route
                  path="/edp-hr-analytics"
                  exact
                  element={<Layout component={HrAnalytics} />}
                /> */}
                <Route
                  path="/edp-adv-financial-management"
                  exact
                  element={<Layout component={FinancialManagement} />}
                />
                <Route
                  path="/edp-adv-human-resource-development"
                  exact
                  element={<Layout component={HumanresourceDevelopment} />}
                />
                <Route
                  path="/edp-leadership-in-sales-&-marketing"
                  exact
                  element={<Layout component={LeadershipSalesMarketing} />}
                />
                <Route
                  path="/edp-strategic-decision-making-in-the-digital-era"
                  exact
                  element={<Layout component={StrategicDecisionMaking} />}
                />
                <Route
                  path="/edp-financial-analytics"
                  exact
                  element={<Layout component={FinancialAnalytics} />}
                />
                <Route
                  path="/edp-digital-marketing"
                  exact
                  element={<Layout component={DigitalMarketing} />}
                />
                <Route
                  path="/edp-hrm-jointly-with-shrm"
                  exact
                  element={<Layout component={HrmWithShrm} />}
                />
                <Route
                  path="/edp-leadership-&-change-management"
                  exact
                  element={<Layout component={LeadershipChangeManagement} />}
                />
                <Route
                  path="/edp-data-science-python-r-excel"
                  exact
                  element={<Layout component={DataScience} />}
                />
                <Route
                  path="/edp-blockchain-for-senior-managers"
                  exact
                  element={<Layout component={BlockChain} />}
                />
                <Route
                  path="/edp-product-management"
                  exact
                  element={<Layout component={ProductManagement} />}
                />
                {/* <Route
              path="/executive-education/vil/short-term-courses/:id"
              exact
              element={<Layout component={CourseDetail} />}
            /> */}
                <Route
                  path="/executive-education/vil/service-partners"
                  exact
                  element={<Layout component={ServicePartners} />}
                />
                <Route
                  path="/about/mandatory-disclosure"
                  exact
                  element={<Layout component={MandatoryDisclosure} />}
                />
                {/* <Route
              path="/newsroom/accreditations"
              exact
              element={<Layout component={Accreditations} />}
            /> */}
                <Route
                  path="/newsroom/accreditations"
                  exact
                  element={<Layout component={AccreditationNew} />}
                />
                {/* <Route
              path="/newsroom/accreditations/:id"
              exact
              element={<Layout component={Accreditations} />}
            /> */}
                <Route
                  path="/newsroom/nirf"
                  exact
                  element={<Layout component={Nirf} />}
                />
                <Route
                  path="/newsroom/nirf/:id"
                  exact
                  element={<Layout component={Nirf} />}
                />
                <Route
                  path="/newsroom/aicte"
                  exact
                  element={<Layout component={Aicte} />}
                />
                <Route
                  path="/newsroom/aicte/:id"
                  exact
                  element={<Layout component={Aicte} />}
                />
                <Route
                  path="/newsroom/fcra"
                  exact
                  element={<Layout component={Fcra} />}
                />
                <Route
                  path="/newsroom/fcra/:id"
                  exact
                  element={<Layout component={Fcra} />}
                />
                <Route
                  path="/xlri-staff"
                  exact
                  element={<Layout component={XlriStaff} />}
                />
                <Route
                  path="/alumni"
                  exact
                  element={<Layout component={AlumniXlriHome} />}
                />
                <Route
                  path="/alumni/newsletter/:id"
                  exact
                  element={<Newsletter />}
                />
                <Route
                  path="/alumni/gallery"
                  exact
                  element={<Layout component={AlumniGallery} />}
                />
                <Route
                  path="/alumni/gallery/video"
                  exact
                  element={<Layout component={AlumniGallery} />}
                />
                <Route
                  path="/scholarships"
                  exact
                  element={<Layout component={Scholarships} />}
                />
                <Route
                  path="/library/journal-current-contents"
                  exact
                  element={<Layout component={CurrentContents} />}
                />
                <Route
                  path="/reaching-xlri"
                  exact
                  element={<Layout component={ReachingXlri} />}
                />
                <Route
                  path="/academic-programmes/admission-procedure/overview/XAT"
                  exact
                  element={<Layout component={AdmissionProcedure} />}
                />
                <Route
                  path="/academic-programmes/admission-procedure/overview/online-program"
                  exact
                  element={<Layout component={AdmissionInformation} />}
                />
                <Route
                  path="/faculty-opening-apply"
                  exact
                  element={<Layout component={CurrentContentsCv} />}
                />
                <Route
                  path="/newsroom/magis"
                  exact
                  element={<Layout component={NewsroomMagis} />}
                />
                <Route
                  path="/newsroom/magis/:id"
                  exact
                  element={<NewsletterMagis />}
                />
                <Route
                  path="/library"
                  exact
                  element={<Layout component={LibraryHome} />}
                />
                <Route
                  path="/library/latest-news"
                  exact
                  element={<Layout component={LibraryLatestNew} />}
                />
                <Route
                  path="/library/latest-news/:id"
                  exact
                  element={<Layout component={LibaryLatestNews} />}
                />
                <Route
                  path="/library/iso-certificate"
                  exact
                  element={<Layout component={IsoCertificate} />}
                />
                <Route
                  path="/course-detail-page"
                  exact
                  element={<Layout component={CourseDetailPage} />}
                />
                <Route
                  path="/assessor-certification-program-on-assessment-centre-approach"
                  exact
                  element={<Layout component={CourseDetailPageTen} />}
                />
                <Route
                  path="/management-programme-in-healthcare-management"
                  exact
                  element={<Layout component={CourseDetailPageTwo} />}
                />
                <Route
                  path="/logistics-and-supply-chain-management"
                  exact
                  element={<Layout component={CourseDetailpageFour} />}
                />
                {/* <Route
                  path="/hr-business-partnering"
                  exact
                  element={<Layout component={CourseDetailPageSeven} />}
                /> */}
                <Route
                  path="/interactive-manager"
                  exact
                  element={<Layout component={CourseDetailPageFive} />}
                />
                <Route
                  path="/finance-for-non-finance-professional-jamshedpur"
                  exact
                  element={<Layout component={CourseDetailPageSix} />}
                />
                <Route
                  path="/demand-business-forecasting"
                  exact
                  element={<Layout component={CourseDetailPageTwo} />}
                />
                {/* <Route
                  path="/hr-analytics"
                  exact
                  element={<Layout component={CourseDetailPageTwo} />}
                /> */}
                <Route
                  path="/think-critically-decide-swiftly-and-communicate-effectively"
                  exact
                  element={<Layout component={CourseDetailPageOne} />}
                />
                <Route
                  path="/strategic-management"
                  exact
                  element={<Layout component={CourseDetailPageOne} />}
                />
                <Route
                  path="/sustainablity-reporting-and-responsible-investment"
                  exact
                  element={<Layout component={CourseDetailPageOne} />}
                />
                <Route
                  path="/certification-program-competency-based-interview"
                  exact
                  element={<Layout component={CourseDetailPageThree} />}
                />
                <Route
                  path="/professional-sales-management"
                  exact
                  element={<Layout component={CourseDetailPageOne} />}
                />
                <Route
                  path="/day-to-day-labour-laws-for-hr-managers"
                  exact
                  element={<Layout component={CourseDetailPageOne} />}
                />
                <Route
                  path="/marketing-decisions-using-simulations"
                  exact
                  element={<Layout component={CourseDetailPageOne} />}
                />
                <Route
                  path="/finance-for-non-finance-professional-delhi"
                  exact
                  element={<Layout component={CourseDetailPageOne} />}
                />
                <Route
                  path="/supply-chain-analytics-jamshedpur"
                  exact
                  element={<Layout component={CourseDetailPageOne} />}
                />
                <Route
                  path="/storytelling-with-data"
                  exact
                  element={<Layout component={CourseDetailPageOne} />}
                />
                <Route
                  path="/content-strategy-for-business-excellence"
                  exact
                  element={<Layout component={CourseDetailPageOne} />}
                />
                <Route
                  path="/marketing-research-for-business"
                  exact
                  element={<Layout component={CourseDetailPageOne} />}
                />
                <Route
                  path="/change-leadership"
                  exact
                  element={<Layout component={CourseDetailPageOne} />}
                />
                <Route
                  path="/designing-resource-based-strategy"
                  exact
                  element={<Layout component={CourseDetailPageOne} />}
                />
                <Route
                  path="/managing-training"
                  exact
                  element={<Layout component={CourseDetailPageOne} />}
                />
                <Route
                  path="/talent-management-line-managers"
                  exact
                  element={<Layout component={CourseDetailPageOne} />}
                />
                <Route
                  path="/supply-chain-analytics-delhi"
                  exact
                  element={<Layout component={CourseDetailPageOne} />}
                />
                <Route
                  path="/luxury-business-marketing"
                  exact
                  element={<Layout component={CourseDetailPageOne} />}
                />
                <Route
                  path="/logistics-suplly-chain-management"
                  exact
                  element={<Layout component={CourseDetailPageOne} />}
                />
                <Route
                  path="/basic-leadership-skills-1"
                  exact
                  element={<Layout component={CourseDetailPageOne} />}
                />
                <Route
                  path="/basic-leadership-skills-batch-2"
                  exact
                  element={<Layout component={CourseDetailPageOne} />}
                />
                <Route
                  path="/student-societies-extra-curricular"
                  exact
                  element={<Layout component={StudentSocietiesOne} />}
                />
                <Route
                  path="/committees-&-interest-groups"
                  exact
                  element={<Layout component={StudentSocietiesTwo} />}
                />
                <Route
                  path="/student-societies-valhalla"
                  exact
                  element={<Layout component={StudentSocietiesThree} />}
                />
                <Route
                  path="/current-student/student-societies"
                  exact
                  element={<Layout component={StudentSocietiesFour} />}
                />
                <Route
                  path="/current-student/student-societies/:id"
                  exact
                  element={<Layout component={StudentSocietiesFour} />}
                />
                <Route
                  path="/student-societies-spicmacay"
                  exact
                  element={<Layout component={StudentSocietiesFive} />}
                />
                <Route
                  path="/student-societies-academic-committee-1"
                  exact
                  element={<Layout component={StudentSocietiesSix} />}
                />
                <Route
                  path="/student-societies-sports-com"
                  exact
                  element={<Layout component={StudentSocietiesSport} />}
                />
                <Route
                  path="/student-societies-sports-com-1"
                  exact
                  element={<Layout component={StudentSocietiesSportOne} />}
                />
                <Route
                  path="/student-societies-extra-curricular-dracula"
                  exact
                  element={<Layout component={StudentSocietiesDracula} />}
                />
                <Route
                  path="/student-societies-co-curricular"
                  exact
                  element={<Layout component={StudentSocietiesCoCurricular} />}
                />
                <Route
                  path="/student-societies-co-curricular-crux"
                  exact
                  element={
                    <Layout component={StudentSocietiesCoCurricularOne} />
                  }
                />
                <Route
                  path="/diploma-advanced-business-strategies"
                  exact
                  element={<Layout component={AdvancedBusinessStrategies} />}
                />
                <Route
                  path="/certificate-human-resource-management"
                  exact
                  element={<Layout component={HumanResourceManagement} />}
                />
                <Route
                  path="/certification-business-analytics"
                  exact
                  element={<Layout component={BusinessAnalytics} />}
                />
                {/* <Route
                  path="/certificate-business-manangement"
                  exact
                  element={<Layout component={BusinessManangement} />}
                /> */}
                {/* <Route
                  path="/certificate-senior-leadership"
                  exact
                  element={<Layout component={SeniorLeadership} />}
                /> */}
                {/* <Route
                  path="/certificate-finance"
                  exact
                  element={<Layout component={CertificateFinance} />}
                /> */}
                <Route
                  path="/certificate-human-capital-leadership"
                  exact
                  element={<Layout component={HumanCapitalLeadership} />}
                />
                <Route
                  path="/isep-activities"
                  exact
                  element={<Layout component={LifeCampus} />}
                />
                <Route
                  path="/informal-committees-&-interest-grps"
                  exact
                  element={<Layout component={StudentSocietiesAxiom} />}
                />
                <Route
                  path="/batch-profile-xol"
                  exact
                  element={<Layout component={BatchProfileXol} />}
                />
                <Route
                  path="/centres/centre-public-policy"
                  exact
                  element={<Layout component={CentrePublicPolicy} />}
                />
                <Route
                  path="/centres/centre-entrepreneurship"
                  exact
                  element={<Layout component={CentreEntrepreneurship} />}
                />
                {/* <Route
                  path="/cxo-club"
                  exact
                  element={<Layout component={CxoClub} />}
                /> */}
                <Route
                  path="/lscm"
                  exact
                  element={<Layout component={DoubleMastersPrgm} />}
                />
                <Route
                  path="/lscm/faq"
                  exact
                  element={<Layout component={FaqDoubleMasters} />}
                />
                <Route
                  path="/support-staff/teaching-assistants"
                  exact
                  element={<Layout component={SupportStaffTeaching} />}
                />
                <Route
                  path="/support-staff/research-associate"
                  exact
                  element={<Layout component={SupportStaff} />}
                />
                {/* test code */}
                <Route
                  path="/support-staff/administrative"
                  exact
                  element={<Layout component={SupportStaff} />}
                />
                {/* test code */}
                {/* <Route
              path="/pgdm-gm-placement-brochure"
              exact
              element={<Layout component={PlacementBrochure} />}
            /> */}
                <Route
                  path="/pgdm-gm-placement-brochure"
                  exact
                  element={<PlacementBrochure />}
                />
                <Route path="/thank-you" exact element={<ThankyouNew />} />
                <Route
                  path="/thank-you-form"
                  exact
                  element={<ThankyouForm />}
                />
                <Route
                  path="/xlri-app-privacy-policy"
                  exact
                  element={<PrivacyPolicyApp />}
                />
                <Route
                  path="/xlri-app-terms-conditions"
                  exact
                  element={<TermsAndConditionsApp />}
                />
                <Route path="/live" exact element={<YoutubeLive />} />
                <Route path="/:id" exact element={<CustomForm />} />
                <Route path="/:id/:secondId" exact element={<CustomForm />} />
                <Route
                  path="/:id/:secondId/:thirdId"
                  exact
                  element={<CustomForm />}
                />
                <Route
                  path="/:id/:secondId/:thirdId/:fourthId"
                  exact
                  element={<CustomForm />}
                />
                <Route
                  path="/:id/:secondId/:thirdId/:fourthId/:fifthId"
                  exact
                  element={<CustomForm />}
                />
                <Route path="/library/:id" exact element={<NewsletterView />} />
                <Route
                  path="/library/efficient-elements"
                  exact
                  element={<Layout component={EfficientElements} />}
                />
                <Route
                  path="/library/think-cell"
                  exact
                  element={<Layout component={ThinkCell} />}
                />
                <Route
                  path="/library/coursera"
                  exact
                  element={<Layout component={Coursera} />}
                />
                <Route
                  path="/library/academic-support"
                  exact
                  element={<Layout component={AcademicSupport} />}
                />
                <Route
                  path="/library/book-takeaway"
                  exact
                  element={<Layout component={BookTakeAway} />}
                />
                <Route
                  path="/library/research-support"
                  exact
                  element={<Layout component={ResearchSupport} />}
                />
                <Route
                  path="/executive-education/mdp/:id"
                  exact
                  element={<Layout component={CourseDetailPageMdp} />}
                />
                <Route
                  path="/student-profile-xol"
                  exact
                  element={<Layout component={XolStudentProfile} />}
                />
                <Route
                  path="/student-profile-xol/:batchParam"
                  exact
                  element={<Layout component={XolStudentProfile} />}
                />
                <Route path="*" exact element={<PageNotFound />} />
                <Route
                  path="/executive-education/vil/long-term-courses/postgraduate-certificate-program-in-csr-leadership"
                  element={
                    <Navigate
                      to="/executive-education/vil/long-term-courses/pgccsrl"
                      replace
                    />
                  }
                />
                {/* <Route
                  path="/alumni/nomination-awards"
                  element={<Navigate to="/alumni-nomination-awards" replace />}
                /> */}
              </Routes>
            </Suspense>
          </BrowserRouter>
        </ApolloProvider>
      </SearchVisibilityProvider>
    </MetaDataProvider>
  );
}

export default App;
